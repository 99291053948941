import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-footer-home',
  templateUrl: './footer-home.component.html',
  styleUrls: ['./footer-home.component.scss']
})
export class FooterHomeComponent implements OnInit {

  setting: any = {
    name: "",
    address: "",
    phone: "",
    fax: "",
    facebook: "",
    line: "",
    title: "",
    detail: "",
    factory: ""
  }
  
  constructor(
    private apiService: ApiService,
    private router: Router
  ) { 
  }

  ngOnInit(): void {
    this.apiService.getSetting().subscribe((res: any) => {
      if(res.status) {
        this.setting = res.data;
      }
    })
  }

  contact() {
    this.router.navigate(["/contact"]);
  }

}
