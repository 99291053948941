import { InjectionToken, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

export let APP_CONFIG = new InjectionToken<AppConfig>('app.config');

export class AppConfig {
    apiEndpoint: String;
}

export const APP_DI_CONFIG: AppConfig = {
    apiEndpoint: environment.api.apiEndpoint
};

@NgModule({
  providers: [
    {
      provide: APP_CONFIG,
      useValue: APP_DI_CONFIG
    }
  ]
})
export class AppConfigModule {}
