
<div class="row px-10">
    <!-- ?begin::card-->

    <div class="col-12 text-center">
        <div class="form-group">
            <!-- basic buttons -->
            <button  
                *ngFor="let category of categories;let i = index" 
                type="button" 
                class="btn btn-orange m-4 px-5"
                [ngClass]="{'btn-orange': categoryId == category._id, 'btn-white-2': categoryId !== category._id  }"
                (click)="selectCategory(category._id)"
            >{{ category.name }}</button>
        </div>
    </div>
    
    <!-- *end::card-->
</div>

<!-- ?begin::"View all" -->
<div class="text-right p-10">
    <a routerLink="/product" class="font-size-h3 text-right text-orange">See more</a> 
</div>
<!-- *end::"View all" -->

<app-product [categoryId]="categoryId" size="8"></app-product>