import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { MessageService } from 'primeng/api';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-main-contact',
  templateUrl: './main-contact.component.html',
  styleUrls: ['./main-contact.component.scss']
})
export class MainContactComponent implements OnInit {
  manageForm: FormGroup;
  submitted = false;
  loading = true;

  setting: any = {
    name: "",
    address: "",
    phone: "",
    fax: "",
    facebook: "",
    line: "",
    title: "",
    detail: "",
    factory: ""
  }
  
  constructor(
    private apiService: ApiService,
    private messageService: MessageService) { 
  }

  ngOnInit(): void {
    this.manageForm = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      companyname: new FormControl(null, [Validators.required]),
      address: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      tel_country: new FormControl(null, [Validators.required]),
      tel_area_code: new FormControl(null, [Validators.required]),
      tel_no: new FormControl(null, [Validators.required]),
      fax_country: new FormControl(),
      fax_area_code: new FormControl(),
      fax_no: new FormControl(),
      email: new FormControl(null, [Validators.required, Validators.email]),
      business_type: new FormControl(),
      how_to_know_us: new FormControl(),
      interested: new FormControl(),
      message: new FormControl(),
      token: new FormControl(null, [Validators.required]) 
    });

    this.apiService.getSetting().subscribe((res: any) => {
      if(res.status) {
        this.setting = res.data;
      }
      this.loading = false;
    })
  }

  get f(){
    return this.manageForm.controls;
  }

  send(): void {
    this.submitted = true
    if (this.manageForm.invalid) {
      for (const control of Object.keys(this.manageForm.controls)) {
        this.manageForm.controls[control].markAsTouched();
      }
      this.messageService.add({key:'alertApp', severity:'error', detail: 'Please input all data'});
      return;
    }

    this.apiService.sendContact(this.manageForm.value).subscribe((res: any) => {
      if(res.status) {
        this.submitted = false;
        this.messageService.add({
          key: "alertApp",
          severity: "success",
          summary: "Send Message success.",
        });
        this.manageForm.reset();
      }
    });
  }

}
