import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutesModule } from './home-routing.module';
import { HeaderHomeComponent } from './components/layout/header-home/header-home.component';
import { FooterHomeComponent } from './components/layout/footer-home/footer-home.component';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CategoryComponent } from './components/category/category.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ProductComponent } from './components/product/product.component';
import { MainProductComponent } from './pages/main-product/main-product.component';
import { PaginatorModule } from 'primeng/paginator';
import { MainContactComponent } from './pages/main-contact/main-contact.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RecaptchaModule, RecaptchaFormsModule, RecaptchaSettings, RECAPTCHA_SETTINGS } from 'ng-recaptcha';
import { DialogModule } from 'primeng/dialog';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ModalViewProductComponent } from './components/modal-view-product/modal-view-product.component';

@NgModule({
  declarations: [
    HomePageComponent,
    CategoryComponent,
    HomeComponent, 
    HeaderHomeComponent,
    FooterHomeComponent,
    ProductComponent,
    MainProductComponent,
    MainContactComponent,
    ModalViewProductComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    PaginatorModule,
    NgbCollapseModule,
    CommonModule,
    HomeRoutesModule,
    NgbModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    DynamicDialogModule,
    DialogModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    ProgressSpinnerModule
  ],
  providers: [
    MessageService,
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcgqVIeAAAAAJkl2zoA4P8LSG5sTfLRlsUGHzUf',
      } as RecaptchaSettings,
    }
  ],
})
export class HomeModule { }
