import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-modal-view-product',
  templateUrl: './modal-view-product.component.html',
  styleUrls: ['./modal-view-product.component.scss']
})
export class ModalViewProductComponent implements OnInit {

  manageProduct: FormGroup;
  categories: any[];
  imageFile: any;
  id = null;
  product = null;
  submitted = false;

  mode = "add";

  constructor(
    public ref: DynamicDialogRef, 
    public config: DynamicDialogConfig,
    private apiService: ApiService,
    public dialog: MatDialog, 
    private messageService: MessageService
  ) { 
    this.id = this.config.data.id;
    this.product = (this.id)? this.config.data.product: null;
    this.mode = (this.id)? "edit": "add";
  }

  ngOnInit(): void { 
    this.manageProduct = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      price: new FormControl(null, [Validators.required]),
      originPrice: new FormControl(null),
      categoryId: new FormControl(null, [Validators.required])
    });
    
    if(this.id) {
      this.manageProduct.patchValue({
        name: this.product.name,
        price: this.product.price,
        originPrice: this.product.originPrice
      });
    }
    
    this.getCategory();
  }

  getCategory() {
    this.apiService.getCategory().subscribe((res:any) => {
      if(res.status) {
        this.categories = res.data
        if(this.id) { 
          let selectCategory = this.categories.filter(m => 
            m._id == this.product.categoryId._id
          );
          this.manageProduct.patchValue({
            categoryId: selectCategory[0]._id
          });
        }
      }
    });
  }

  onSelectImageFile(event) {
    this.imageFile = event.currentFiles[0];
  }

  get f(){
    return this.manageProduct.controls;
  }

  onSubmit(){
    this.submitted = true;  

    if (this.manageProduct.invalid) {
      this.messageService.add({key:'alertApp', severity:'error', detail: 'Please input all data'});
      return;
    }

    if(!this.imageFile && this.mode == "add") {
      this.messageService.add({key:'alertApp', severity:'error', detail: 'Please upload image product'});
      return;
    }

    var formData: any = new FormData();

    formData.append('name', this.manageProduct.get('name').value);
    formData.append('price', this.manageProduct.get('price').value);
    formData.append('originPrice', this.manageProduct.get('originPrice').value);
    formData.append('categoryId', this.manageProduct.get('categoryId').value);
    if( this.imageFile ) {
      formData.append('image', this.imageFile);
    }

    if(this.mode == "add") {
  
      this.apiService.addProduct(formData).subscribe( (res:any) => {
        if( res.status ) {
          this.messageService.add({ 
            key:'alertApp', 
            severity:'success', 
            summary:'Add Product success' 
          });
          this.close("success");
        } else {
          this.messageService.add({ 
            key:'alertApp', 
            severity:'error', 
            summary: "Add Product Fail"
          });
        }
      }, ( error:any )=> {
        this.messageService.add({ 
          key:'alertApp', 
          severity:'error', 
          summary: error.error.data
        });
      });

    } else {
      this.apiService.updateProduct(this.id, formData).subscribe( (res:any) => {
        if( res.status ) {
          this.messageService.add({ 
            key:'alertApp', 
            severity:'success', 
            summary:'Update Product success' 
          });
          this.close("success");
        } else {
          this.messageService.add({ 
            key:'alertApp', 
            severity:'error', 
            summary: "Update Product Fail"
          });
        }
      }, ( error:any )=> {
        this.messageService.add({ 
          key:'alertApp', 
          severity:'error', 
          summary: error.error.data
        });
      });
    }
  }

  close(status) {
    this.ref.close(status);
  }

}
