<!-- <p>header-home works!</p> -->
<!-- ?begin::nav -->
<nav class="navbar navbar-expand-lg navbar-light">
    <div class="img-logo d-sm-block d-md-block d-lg-none d-none" >
        <a routerLink="/home">
            <img alt="Logo" src="assets/images/logo.png" width="50%" />
        </a>
    </div>

    <!-- ?begin::buttom-nav-mobile -->
    <button class="navbar-toggler border-0 font-size-h3" type="button" 
        (click)="isCollapsed = !isCollapsed"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="header11"
    >
        <span class="navbar-toggler-icon"></span>
    </button>
    <!-- *end::buttom-nav-mobile -->

    <div class="collapse navbar-collapse  flex-column" id="header11"  [ngbCollapse]="isCollapsed">
        <ul class="justify-content-around mx-auto navbar-nav" >
            <li class="nav-item mx-xxl-10 m-md-1 w-150px" style="width: 5rem;"  [ngClass]="{'pt-10': isCollapsed, 'pt-5': !isCollapsed }">
                <a  pRipple
                    class="nav-link font-size-h6-xxl font-size-sm-md " 
                    [ngClass]="{'text-center': isCollapsed}"
                    [routerLink]="['/home']" 
                    routerLinkActive="font-weight-normal active"
                >HOME</a>
            </li>
            <li class="nav-item mx-xxl-10 m-md-1 w-150px" style="width: 10rem;"  [ngClass]="{'pt-10': isCollapsed, 'pt-5': !isCollapsed }">
                <a  pRipple
                    class="nav-link font-size-h6-xxl font-size-sm-md" 
                    [ngClass]="{'text-center': isCollapsed}"
                    [routerLink]="['/product']" 
                    routerLinkActive="font-weight-normal active"
                >OUR PRODUCTS</a>
            </li>
            <a routerLink="/"  style="width: 10%;" [ngClass]="{'d-block': isCollapsed, 'd-none': !isCollapsed }"> 
                <img alt="Logo" src="assets/images/logo.png" style="width: 100%;"/>
            </a>
            <li class="nav-item mx-xxl-10 m-md-1 w-150px" [ngClass]="{'pt-10': isCollapsed, 'pt-5': !isCollapsed }">
                <a  pRipple
                    class="nav-link font-size-h6-xxl font-size-sm-md"
                    [ngClass]="{'text-center': isCollapsed}"
                    [routerLink]="['/contact']" 
                    routerLinkActive="font-weight-normal active"
                >CONTACT US</a>
            </li>
            <li class="nav-item w-sm-200px mx-xxl-10 m-md-1" [ngClass]="{'pt-10': isCollapsed, 'pt-5': !isCollapsed }">
                <div class="input-group ">
                    <input class="form-control" type="text" placeholder="Search Product Name" aria-label="Search" [(ngModel)]="key">
                    <div class="input-group-append">
                        <button class="btn btn-outline-secondary btn-sm"  (click)="search()"><i class="fa fa-search"></i> </button>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</nav>
<!-- *end::nav -->


