import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  loading = true;
  setting: any = {
    name: "",
    address: "",
    phone: "",
    fax: "",
    facebook: "",
    line: "",
    title: "",
    detail: "",
    factory: ""
  }
  constructor(private apiService: ApiService) { 
  }

  ngOnInit(): void {
    this.apiService.getSetting().subscribe((res: any) => {
      this.loading = false;
      if(res.status) {
        this.setting = res.data;
      }
    })
  }

}
