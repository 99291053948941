import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { throwError, Observable } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { APP_CONFIG, AppConfig } from '../add-config.module';

@Injectable({
  providedIn: 'root'
})

export class ApiService {


  constructor(
    private httpClient: HttpClient, 
    @Inject(APP_CONFIG) private _config: AppConfig
  ) { }

  httpHeaders = new HttpHeaders().set(
    'Content-Type','application/json'
  );

  getCategory() {
    return this.httpClient.get(this._config.apiEndpoint + `category`);
  }

  getCategoryById(id: number) {
    return this.httpClient.get(this._config.apiEndpoint + `category/${id}`);
  }

  addCategory(category: object) {
    return this.httpClient.post<any>(this._config.apiEndpoint +`category`, category);
  }

  updateCategory(id, category) { 
    return this.httpClient.put<any>(this._config.apiEndpoint +`category/${id}`, category);
  }

  deleteCategory(id) { 
    return this.httpClient.delete<any>(this._config.apiEndpoint +`category/${id}`);
  }

  getProductImage(url) {
    return this.httpClient.get(this._config.apiEndpoint + `product/image?imagepath=` + url, { responseType: 'blob' })
    .pipe(map(data => data));
  }

  getProduct() {
    return this.httpClient.get(this._config.apiEndpoint + `product`);
  }

  getProductSearch(param: any) {
    let query = '?';
    query += (param.categoryId)? `categoryId=${param.categoryId}&`: "";
    query += (param.key)? `key=${param.key}&`: "";
    query += (param.size)? `limit=${param.size}&`: "";
    query += (param.page)? `page=${param.page}&`: "";
    return this.httpClient.get(this._config.apiEndpoint + `product/search` + query);
  }

  getProductById(id: number) {
    return this.httpClient.get(this._config.apiEndpoint + `product/${id}`);
  }

  addProduct(product: any) {
    return this.httpClient.post<any>(this._config.apiEndpoint +`product`, product);
  }

  updateProduct(id, product) { 
    return this.httpClient.put<any>(this._config.apiEndpoint +`product/${id}`, product);
  }

  deleteProduct(id) { 
    return this.httpClient.delete<any>(this._config.apiEndpoint +`product/${id}`);
  }

  getSetting() {
    return this.httpClient.get(this._config.apiEndpoint + `setting`);
  }

  updateSetting(id, setting) { 
    return this.httpClient.put<any>(this._config.apiEndpoint +`setting/${id}`, setting);
  }

  sendContact(data: any) {
    return this.httpClient.post<any>(this._config.apiEndpoint +`sendmail`, data);
  }
} 