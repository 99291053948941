<div mat-dialog-title style="margin-bottom: 10px;">
  <div class="d-flex justify-content-between">
      <div class="text-blue">
          Export Data
      </div>
      <div>
          <img src="assets/icons/icon-close.svg" (click)="close()" height="30px" style="float: right;cursor: pointer;"/>
      </div>
  </div>
</div>
<mat-dialog-content>
  <div class="p-2">
    <mat-form-field color="accent" appearance="fill">
    <mat-label>From</mat-label>
    <input matInput [matDatepicker]="dateFrom" [(ngModel)]="data.dateFrom" readonly  (ngModelChange)="checkDate()">
    <mat-datepicker-toggle matSuffix [for]="dateFrom"></mat-datepicker-toggle>
    <mat-datepicker #dateFrom color="primary"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="p-2">
    <mat-form-field color="accent" appearance="fill">
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="dateTo" [(ngModel)]="data.dateTo" readonly (ngModelChange)="checkDate()">
      <mat-datepicker-toggle matSuffix [for]="dateTo"></mat-datepicker-toggle>
      <mat-datepicker #dateTo color="primary"></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="validate">{{message}}</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button [mat-dialog-close]="data" [disabled]="btnValidate" cdkFocusInitial class="btn btn-primary">Download</button>
</mat-dialog-actions>