<!-- <p>footer-home works!</p> -->
<!-- begin:: footer -->
<section class="footer bottom-0 footer position-static w-100" id="footer" >
    <div class="bg-dark">

        <div class="f2-middle mt-30 footer3">
            <div class="container">
                <div class="row">
                    <!-- Column -->
                    <div class="col-lg-8 col-md-8 col-sm-6">
                        <div class="my-10 mx-10">
                            <h4 class="text-white"><span style="letter-spacing: 3px;">{{ setting.name }}</span></h4>
                            <div class="round-social light my-10">
                                <p class="text-white pt-5">
                                    {{ setting.address }}
                                </p>
                                <p class="text-white">
                                    <i class="fas fa-phone text-white pr-2"></i>  Tel : {{ setting.phone }}
                                </p>
                                <p class="text-white">
                                    <i class="fas fa-fax text-white pr-2"></i>  Fax : {{ setting.fax }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- Column -->
                    <!-- Column -->
                    <div class="col-lg-4 col-md-4 col-sm-6 d-flex justify-content-md-start">
                        <div class="my-10 mx-10">
                            <h4 class="text-white"><span style="letter-spacing: 3px;">Contact us</span></h4>
                            <div class="round-social light my-10">
                                <span class="text-white">
                                    <a 
                                        href="https://www.facebook.com/srtoy/" 
                                        target="_new" 
                                        class="link" 
                                        title="Facebook"
                                    >
                                        <i class="socicon-facebook text-dark icon-lg"></i>
                                    </a> {{ setting.facebook }}
                                </span>
                                <br>
                                <span class="py-2">
                                    <a 
                                        href="https://lin.ee/H1RxdbH" 
                                        class="link" 
                                        target="_new" 
                                        title="Voice of Customer"
                                    >
                                        <i class="fab fa-line text-dark icon-lg"></i>
                                    </a>
                                    <span class="text-white">@ {{ setting.line }}</span>
                                </span>
                                <br>
                                <button class="btn btn-orange mt-10" (click)="contact()"> <i class="fa fa-envelope-o text-white"></i> click send email</button>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container pb-5">
            <div class="row">
                <div class="col-md-12 col-sm-12 d-flex justify-content-md-center">
                    <div class="mt-5 ml-3">
                        <span class="text-white">Copyright © 2022 SRtoy, All rights reserved.</span>
                    </div>
                </div>
                
            </div>
        </div>
    
    </div>
</section>
<!-- end:: footer -->