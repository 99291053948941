import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss']
})
export class HeaderHomeComponent implements OnInit {
  @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  isCollapsed = true;
  scrollTop: any;
  isCustomer: any;
  key = "";
  constructor(private router: Router,) { 
  }

  ngOnInit(): void {
  }

  scrollToBottom(): void {
    const scrollingElement = (document.scrollingElement || document.body);
    document.documentElement.style.scrollBehavior = 'smooth';
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
    document.documentElement.style.scrollBehavior = 'auto';
  }

  logout() {
    document.location.reload();
  }

  search() {
    this.router.navigate(['/product'], { queryParams: { key: this.key  } });
  }
}
