import { Component, OnInit, Inject  } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading = false;
  submitted = false;
  error :any;
  user;
  password;
  validate = '';
  remember = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private authenticationService: AuthenticationService)
   { 
    this.matIconRegistry.addSvgIcon(
      "icon-user",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-user.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "icon-lock",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/icon-lock.svg")
    );
  }

  ngOnInit(): void {
    this.validate = ""
    localStorage.clear();
  }

  signin() {
    if(!this.user || !this.password){
      this.validate = "Please complete all information."
      this.loading = false;
    }else{
      this.validate = ""
      // localStorage.setItem('user', "admin")
      // this.router.navigate(['home']);
      //let username = ;
      //let password = ; 
      this.authenticationService.login(this.user, this.password)
      .subscribe(
        data => {
          this.router.navigate(['admin/home']);
        },
        error => {
         if(error.status == 401){
            this.validate = 'Invalid username or password'
         }else{
            this.validate = 'Cannot connect to the server'
         }
        }
      );
    }
  }
}
