
<!-- ?begin:: cover -->
<div class="loading-indicator" *ngIf="loading">
	<p-progressSpinner strokeWidth="5"></p-progressSpinner>
</div>
<div class="section-1">
	<div class="form-group py-lg-20 py-md-15 px-lg-5 text-white">
		<div class="col-xl-4 col-lg-6 col-md-7 col-sm-12 col-12 mt-lg-10 mt-xxl-30">
			<div class="h-xxl-500px p-25 p-md-23 p-sm-22 p-xl-30 pt-xxl-40 w-md-550px w-sm-450px w-xl-700px w-xxl-800px" style="
				background-image: url(assets/images/box-text_cover.png);
				background-repeat: round;"
			>
				<span class="font-size-h4-xl font-size-h5-lg font-weight-bolder text-dark">{{ setting.title }}</span>
				<br>
				<br>
				<span class="font-size-h4-xl font-size-h5-lg text-dark">
					{{ setting.detail }}
				</span>
			</div>
		</div>
	</div>
</div>
<!-- *end:: cover -->
<!-- ?begin:: section 2 -->
<div class="section-2">
	<div class="form-group py-lg-20 py-md-15 px-lg-5 text-white text-left">
		<div class="col-lg-6 col-md-7 col-sm-8 col-xl-6 mt-lg-10 offset-lg-6 offset-md-5 offset-sm-4 offset-xl-6 pl-sm-10">
			<div class="font-size-h4-xl font-size-h5-lg p-22">
				<span class="text-dark">
					{{ setting.factory }}
				</span>
			</div>
		</div>
	</div>
</div>
<!-- *end:: section 2 -->
<!-- ?begin:: section 3 -->
<div class="container-fluid bg-white">
	<div class="container">
		<div class="form-group py-lg-20 py-md-15 px-lg-5 text-white text-center">
			<div class="col-12 mt-lg-10">
				<span class="font-size-h1 font-weight-bolder text-dark" style="letter-spacing: 8px;">OUR Products</span>
			</div>
		</div>
		<!-- ?begin::"Collection" -->
		<app-category></app-category>
		<!-- ?end::"Collection" -->
	</div>
</div>
<!-- *end:: section 3  -->
<!-- ?begin:: section 4 -->
<div class="section-4">
	<div class="form-group py-lg-20 py-md-15 px-lg-5 text-white text-left">
		<div class="col-12 mt-lg-10">
			<div class="text-center font-size-h4-xl font-size-h5-lg pt-xl-40 pt-20">
				<h1 class="text-white">
					<span >Please fill in this form and click send when complete</span>
				</h1>
				<a [routerLink]="['/contact']"  class="btn btn-green btn-large mt-10"> click send email </a>
			</div>
		</div>
	</div>
</div>
<!-- *end:: section 4 -->
<!-- ?begin:: section 5 -->
<div class="container-fluid bg-white">
	<div class="container">
		<div class="form-group pt-lg-30 pt-md-30 px-lg-5 text-white text-center">
			<div class="col-12 mt-lg-10">
				<span class="font-size-h1 font-weight-bolder text-dark" style="letter-spacing: 8px;">What's new</span>
			</div>
		</div>
		<app-product size="4"></app-product>
	</div>
</div>
<!-- *end:: section 5  -->
