<div class="row px-10" [ngClass]="{'justify-content-center': size <= '4'}">
    <div class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 my-10 mx-xxl-5" *ngFor="let product of products;let i = index" >
        <div class="card">
            <div class="card-body">
                <img
                    (click)="viewProduct(product)"
                    class="img-project" 
                    [src]="product.image" 
                    [alt]="product.title"
                >
            </div>
        
            <div class="p-5">
                <div (click)="viewProduct(product)">
                    <h5 class="text-dark">
                        {{ product.name }}
                    </h5>
                    <span class="price" *ngIf="product.originPrice > 0">
                        {{ product.originPrice | number:'0.2-2' }}
                    </span>
                    <span class="price-sale">
                        ฿ {{ product.price | number:'0.2-2' }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>
