<div class="loading-indicator" *ngIf="loading">
	<p-progressSpinner strokeWidth="5"></p-progressSpinner>
</div>
<div class="contrainer bg-white">
  <div class="row p-20">
    <div class="col-12 text-center">
      <h1 style="letter-spacing: 8px;">Our Products</h1>
    </div>
  </div>
  
  <div class="row px-20">
    <div class="col-12 text-center">
        <div class="form-group">
            <button  
                *ngFor="let category of categories;let i = index" 
                type="button" 
                class="btn m-4 px-5"
                [ngClass]="{'btn-orange': categoryId == category._id, 'btn-white-2': categoryId !== category._id  }"
                (click)="selectCategory(category._id)"
            >{{ category.name }}</button>
        </div>
    </div>
  </div>
  <div class="row px-20">
    <div class="col-12">
      <app-product 
        [categoryId]="categoryId" 
        [page]="page" 
        size="12"
        (total)="setTotal($event)"
        (status)="setLoading($event)"
      ></app-product>
    </div>
  </div>

  <div class="row px-20 pb-20">
    <div class="col-12" *ngIf="total > 0; else noData">
      <p-paginator [rows]="12" [totalRecords]="total" (onPageChange)="paginate($event)"></p-paginator>
    </div>

    <ng-template #noData>
      <div class="col-12">
        <div class="p-10 text-center" style="color: #EC650A;">
          <h2>Coming Soon</h2>
        </div>
      </div>
    </ng-template>
  </div>
</div>
