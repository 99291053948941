<div class="loading-indicator" *ngIf="loading">
	<p-progressSpinner strokeWidth="5"></p-progressSpinner>
</div>
<div class="contrainer bg-white">
    <div class="row p-20">
        <div class="col-md-4 col-sm-12">
            <h5 style="letter-spacing: 2px;">{{ setting.name }}</h5>
            <span>
                {{ setting.address }}
            </span>
        </div>
        <div class="offset-md-2 col-md-3 col-sm-12">
            <div class="pb-2"><i class="fas fa-phone text-dark icon-lg pr-2"></i> Tel : {{ setting.phone }}</div>
            <div class="py-2"><i class="fas fa-fax text-dark icon-lg pr-2"></i> Fax : {{ setting.fax }}</div>
        </div>

        <div class="col-md-3 col-sm-12">
            <span class="py-2">
                <a href="https://www.facebook.com/srtoy/" target="_new"  class="text-dark pb-2"><i class="socicon-facebook text-dark icon-lg pr-2"></i> {{ setting.facebook }}</a>
            </span>
            <br>
            <span class="py-2">
                <a href="https://lin.ee/H1RxdbH" target="_new" class=" text-dark py-2"><i class="fab fa-line text-dark icon-lg pr-2"></i> @{{ setting.line }}</a>
            </span>
        </div>
    </div>

    <div class="row px-20">
        <div class="col-sm-12">
            <h5>Please fill in this form and click send when complete.</h5>
        </div>
    </div>

    <form [formGroup]="manageForm">
        <div class="row px-20 pt-20">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="name">Name</label>
                    <input type="text" class="form-control" formControlName="name" [ngClass]="{'border-danger':submitted && f.name.errors }" />
                    <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="text-danger">
                        <div *ngIf="f.name.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="companyname">Company Name</label>
                    <input type="text" class="form-control" formControlName="companyname" [ngClass]="{'border-danger':submitted && f.companyname.errors }" />
                    <div *ngIf="f.companyname.invalid && (f.companyname.dirty || f.companyname.touched)" class="text-danger">
                        <div *ngIf="f.companyname.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="address">Address</label>
                    <input type="text" class="form-control" formControlName="address" [ngClass]="{'border-danger':submitted && f.address.errors }" />
                    <div *ngIf="f.address.invalid && (f.address.dirty || f.address.touched)" class="text-danger">
                        <div *ngIf="f.address.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="country">Country</label>
                    <input type="text" class="form-control" formControlName="country" [ngClass]="{'border-danger':submitted && f.country.errors }" />
                    <div *ngIf="f.country.invalid && (f.country.dirty || f.country.touched)" class="text-danger">
                        <div *ngIf="f.country.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row px-20">
            <div class="col-sm-12">
                <i class="fas fa-phone text-dark icon-lg pr-2"></i> <span class="text-dark"> Telephone</span>
            </div>
            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="tel_country">Country</label>
                    <input type="text" class="form-control" formControlName="tel_country" [ngClass]="{'border-danger':submitted && f.tel_country.errors }" />
                    <div *ngIf="f.tel_country.invalid && (f.tel_country.dirty || f.tel_country.touched)" class="text-danger">
                        <div *ngIf="f.tel_country.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="tel_area_code">Area code</label>
                    <input type="number" class="form-control" formControlName="tel_area_code" [ngClass]="{'border-danger':submitted && f.tel_area_code.errors }" />
                    <div *ngIf="f.tel_area_code.invalid && (f.tel_area_code.dirty || f.tel_area_code.touched)" class="text-danger">
                        <div *ngIf="f.tel_area_code.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="tel_no">No.</label>
                    <input type="number" class="form-control" formControlName="tel_no" [ngClass]="{'border-danger':submitted && f.tel_no.errors }" />
                    <div *ngIf="f.tel_no.invalid && (f.tel_no.dirty || f.tel_no.touched)" class="text-danger">
                        <div *ngIf="f.tel_no.errors?.required">This field is required.</div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <i class="fas fa-fax text-dark icon-lg pr-2"></i> <span class="text-dark"> Fax</span>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="fax_country">Country</label>
                    <input type="text" class="form-control" formControlName="fax_country" />
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="fax_area_code">Area code</label>
                    <input type="number" class="form-control" formControlName="fax_area_code" />
                </div>
            </div>

            <div class="col-md-4 col-sm-12">
                <div class="form-group">
                    <label for="fax_no">No.</label>
                    <input type="number" class="form-control" formControlName="fax_no" />
                </div>
            </div>
        </div>

        <div class="row px-20">
            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" formControlName="email" [ngClass]="{'border-danger':submitted && f.email.errors }" />
                    <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="text-danger">
                        <div *ngIf="f.email.errors?.required">This field is required.</div>
                        <div *ngIf="f.email.errors?.email">Please input email format.</div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-12">
                <div class="form-group">
                    <label for="business_type">Business Type</label>
                    <input type="text" class="form-control" formControlName="business_type" />
                </div>
            </div>

            <div class="col-sm-12">
                <div class="form-group">
                    <label for="how_to_know_us">How to know us</label>
                    <input type="text" class="form-control" formControlName="how_to_know_us" />
                </div>
            </div>

            <div class="col-sm-12">
                <div class="form-group">
                    <label for="interested">Product categories you,re interested</label>
                    <textarea class="form-control" formControlName="interested" pInputTextarea rows="3"></textarea>
                </div>
            </div>

            <div class="col-sm-12">
                <div class="form-group">
                    <label for="message">Message</label>
                    <textarea class="form-control" formControlName="message" pInputTextarea rows="3"></textarea>
                </div>
            </div>
        </div>

        <div class="w-100"> 
            <div class="row px-20 pb-20">
                <div class="col-sm-12 mb-2">
                    <re-captcha 
                        id="recaptcha" 
                        name="recaptcha"
                        formControlName="token">
                    </re-captcha>
                    <div *ngIf="manageForm.get('token').invalid && (manageForm.get('token').dirty || manageForm.get('token').touched)" class="text-danger">
                        <div *ngIf="manageForm.get('token').errors?.required">This field is required.</div>
                    </div>
                </div>

                <div class="col-sm-12 pt-10 text-right">
                    <button type="submit" class="btn btn-orange" (click)="send()"> <i class="fa fa-envelope-o text-white"></i> click send email</button>
                </div>
            </div>
        </div>
    </form>
</div>
