import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ExportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) 
     { }

  btnValidate = false
  message = ""
  ngOnInit(): void {

  }

  close(): void {
    this.dialogRef.close();
  }

  checkDate(){
    this.message = ""
    this.btnValidate = false
    if(this.data.dateTo.getTime() < this.data.dateFrom.getTime() ){
      this.btnValidate = true
      this.message ="Start date must not exceed End date"
    }
  }
}
