import { AuthenticationService } from './authentication.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { tap, catchError } from "rxjs/operators";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authService: AuthenticationService, private router: Router) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const Token = currentUser?.token;
    const token = (Token) ? Token : null;
    const role = (currentUser) ? currentUser.role : null;

    if(req.url.indexOf("/auth/login") === -1){
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      });
    }

    return next.handle(req).pipe(
      tap((ev: HttpEvent<any>) => { }),
      catchError((error: HttpErrorResponse) => {
        // cann't access admin
        if (error.status === 401) {
          this.authService.logout();
          this.router.navigate(['/login']);
        }else if(error.status === 401){
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }
}

