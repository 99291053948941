import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor( private primengConfig: PrimeNGConfig) { 

  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;
  }

  onActivate(event) {
    // window.scroll(0,0);

    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth' 
     });
 }

}
