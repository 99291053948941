<div class="login row">  
    <div class="col-md-12">
        <div class="sign-in">
            Log in
        </div>
        <span>Login to srtoy manage</span>
    </div>
    <div class="col-md-12 pt-3">
        <mat-form-field>
            <mat-label>
                <mat-icon svgIcon="icon-user"></mat-icon>
                Username
            </mat-label>
            <input matInput placeholder="Username" [(ngModel)]="user">
        </mat-form-field>
    </div>
    <div class="col-md-12">    
        <mat-form-field>
            <mat-label>
                <mat-icon svgIcon="icon-lock"></mat-icon>
                Password
            </mat-label>
            <input matInput placeholder="Password" type="password" [(ngModel)]="password" (keyup.enter)="signin()">
        </mat-form-field>
    </div>
    <div class="validate"> {{validate}}</div>
    <div class="col-md-12 pt-4">
        <button (click)="signin()" class="btn btn-success" >Log in</button>
    </div>
</div> 