<!-- Modal -->
<div class="modal-body">
    <div class="card p-3">
      <div class="card-body">
          <img
              class="img-project" 
              [src]="product.image" 
              [alt]="product.title"
          >
      </div>
    
      <div class="row">
          <div class="col-sm-12">
              <h2>Category: {{ product?.categoryId?.name}}</h2>
          </div>
      </div>

      <div class="row">
        <div class="text-right col-sm-12">
          <h3 class="price" *ngIf="product.originPrice > 0">
            {{ product?.originPrice | number:'0.2-2' }}
          </h3>
          <h3 class="price-sale">
              ฿ {{ product?.price | number:'0.2-2' }}
          </h3>
        </div>
      </div>
    </div>
  </div>

