import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { category } from  './category';

@Component({
  selector: 'app-main-product',
  templateUrl: './main-product.component.html',
  styleUrls: ['./main-product.component.scss']
})
export class MainProductComponent implements OnInit {
  categoryId: any = "";
  categories = []
  total = 0;
  page = 1;
  loading = true;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getCategory().subscribe((res: any) => {
      if(res.status) {
        this.categories = res.data;
      }
    });
  }

  paginate(event) {
    //event.first = Index of the first record
    //event.rows = Number of rows to display in new page
    //event.page = Index of the new page
    //event.pageCount = Total number of pages
    this.page = (event.page + 1);
  }

  selectCategory(id) {
    this.loading = true;
    this.categoryId = id;
  }

  setTotal($event) {
    this.total = $event;
  }

  setLoading($event){
    this.loading = $event;
  }

}
