import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { ModalViewProductComponent } from '../modal-view-product/modal-view-product.component';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  providers: [DialogService]
})
export class ProductComponent implements OnInit {
  @Input() categoryId: any = "";
  @Input() products: any[] = [];
  @Input() size: string = "12";
  @Input() page: Number = 1;
  @Output() total = new EventEmitter();
  @Output() status = new EventEmitter();
  eventsSubscription: Subscription;
  key: string;

  constructor(
    private dialogService: DialogService,
    private apiService: ApiService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.key = params.key;
        this.getProducts(this.categoryId, this.page, this.key);
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.categoryId && !changes.categoryId.firstChange)
      this.categoryId = changes.categoryId.currentValue;
    
    if(changes.page && !changes.page.firstChange)
      this.page = changes.page.currentValue;

    if( (changes.categoryId && !changes.categoryId.firstChange) || (changes.page && !changes.page.firstChange))
      this.getProducts(this.categoryId, this.page,this.key);
  }

  getProducts(id, page, key) {
    this.apiService.getProductSearch({
      categoryId: id,
      size: this.size,
      page: page,
      key: key
    }).subscribe(async (res: any) => {
      if( res.status ) {
        for (const product of res.data.rows) {
          product.image = await this.getImage(product.image);
        }
        if(this.size === "12") {
          this.products = res.data.rows;
        } else if(this.size === "4"){
          this.products = res.data.rows;
        } else {
          this.products = res.data.rows;
        }
        this.total.emit(res.data.total)
        this.key = ""
        this.status.emit(false);
      }
    });
  }


  getImage(url) {
		var promise = new Promise((resolve, reject) => {
			this.apiService.getProductImage(url).subscribe((res: any) => {
				let reader = new FileReader();
				reader.addEventListener( "load",
					() => {
						resolve(reader.result);
					}, false
				);

				if (res) {
					reader.readAsDataURL(res);
				}
			}, error => {
				resolve("")
			});
		});
		return promise;
	}

  viewProduct(product){
    console.log(product)
    
    let ref = this.dialogService.open(ModalViewProductComponent, {
			baseZIndex: 1,
			width: "50%",
			header: product.name,
			data: { 
        id: product._id,
        product: product
      },
		});

		ref.onClose.subscribe((res) => {
			if (res === "success") {
				// this.getProduct();
			}
		});
  }

}
