// import { AuthService, UserModel } from 'src/app/modules/auth';
// import { CmuApi } from 'src/app/_models/CMUApi';
// import { ProjectService } from 'src/app/services/project.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { category } from  './category';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(1000, style({ opacity: 1 }))
      ])
    ])
  ]
})
export class CategoryComponent implements OnInit, OnDestroy {
  categoryId: any = null;
  categories = [];
  products = [];

  constructor(
    private apiService: ApiService
  ) {

  }

  ngOnInit(): void {
    this.apiService.getCategory().subscribe((res: any) => {
      if(res.status) {
        this.categories = res.data;
        // this.categoryId = this.categories[0]._id;
      }
    })
    
    // this.user = this.authService.getUserDetail() || this.user;
    // this.getprojects();
    // if (this.events) {
    //   this.eventsSubscription = this.events.subscribe((num) => this.getmoreprojects(num));
    // }
  }

  selectCategory(id) {
    this.categoryId = id;
  }
  
  ngOnDestroy() {

  }

}
